import { Subscription } from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "src/environments/environment";
import { DomService } from "./dom.service";
import {
  DATA_ATTRIBUTES_TRACKED,
  customDimensionMapper,
} from "../constants/data-attributes";
import { GA_EVENTS, GA_EVENT_OPTIONS } from "../constants/ga-events";
import { ProjectsService } from "./projects.service";
import { FilterService } from "src/app/@shared/filter/services/filter.service";
import { AuthService } from "../authentication/auth.service";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { DashboardService } from "src/app/v2/dashboard/services/dashboard.service";
import { GAHelperService } from "../services/ga-helper.serice";
import * as localForage from "localforage";
import { filter, startWith, pairwise } from "rxjs/operators";
declare var gtag: Function;

//userDimentions - that need to be sent with every event
interface UserDimensions {
  User_identifier: string;
  User_group: string;
  UTM_medium: string;
}

@Injectable({
  providedIn: "root",
})
export class GAnalyticsService implements OnDestroy {
  googleAnalyticsId;
  subscription: Subscription;
  gaUser;

  constructor(
    private dom: DomService,
    private router: Router,
    private project: ProjectsService,
    private auth: AuthService,
    private filter: FilterService,
    private dashboard: DashboardService,
    private gaHelper: GAHelperService
  ) {
    this.googleAnalyticsId = environment.googleAnalyticsId;
  }

  initialiseGAConfig() {
    const gtag = (<any>window).gtag;
    const { appUrl, googleAnalyticsId } = environment;
    const pathname = window.location.pathname;
    const startIndex = 3;
    const dimensionIndexStart = 6;
    const customDimensions = customDimensionMapper(
      startIndex,
      dimensionIndexStart
    );

    gtag("config", environment.googleAnalyticsId, {
      send_page_view: false,
      page_title: `Zetwerk Customer`,
      page_path: pathname,
      custom_map: {
        dimension1: "User_identifier",
        dimension2: "User_group",
        dimension3: "Contract_number",
        dimension4: "Project_id",
        dimension5: "UTM_medium",
        ...customDimensions,
      },
      linker: {
        domains: [appUrl],
      },
    });

    gtag("set", {
      send_to: googleAnalyticsId,
      ...this.auth.gAUserDimensions,
    });

    const pathToPageTitleMap = {
      //dashboards
      login: "Login Dashboard",
      summary: "Main Dashboard",
      projects: "Project Dashboard",
      //project management
      lots: "Dispatch Timeline",
      drawings: "Drawings Allocation",
      payments: "Payment Summary",
      documents: "Project Documents",
    };

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let [primary_path, secondary_path, tertiary_path] = event.url
          .split("/")
          .filter((str) => str);


        if (secondary_path?.slice(0, 4) == "lots" && !tertiary_path) return; //skip intermediary routing event while resolving

        if (secondary_path?.includes("?"))
          //remove trailing query params if present
          secondary_path = secondary_path?.slice(
            0,
            secondary_path?.search("\\?")
          );

        let event_category, projectId, contractNumber;

        if (primary_path == "dashboard") {
          event_category = "Dashboard";
          contractNumber = "N/A";
          projectId = "N/A";
        } else {
          event_category = "Project Management";
          contractNumber = this.project.selectedProject?.projectNumber;
          projectId = primary_path;
        }

        console.log("sending page_view event for ... ", primary_path, secondary_path);
        
        if((!primary_path || primary_path=='dashboard' ) && !secondary_path) {
          gtag("event", "page_view", {
            send_to: this.googleAnalyticsId,
            event_category,
            page_path: event.url,
            page_title: 'Main Dashboard',
            Project_id: 'N/A',
            Contract_number: 'N/A',
            User_identifier: this.auth.gAUserDimensions.User_identifier,
          });
        } else if(primary_path && secondary_path) {
          gtag("event", "page_view", {
            send_to: this.googleAnalyticsId,
            event_category,
            page_path: event.url,
            page_title: pathToPageTitleMap[secondary_path],
            Project_id: projectId,
            Contract_number: contractNumber,
            User_identifier: this.auth.gAUserDimensions.User_identifier,
          });
        }
      });
  }

  private dispatchGAEvent({ action, event_category, event_label, values }) {
    gtag("event", action, {
      send_to: this.googleAnalyticsId,
      event_category: event_category,
      event_label: event_label,
      ...values,
    });
  }

  async sendGAEvent(gaEvent: string, values: any = {}) {
    const user = await this.auth.getUser();
    const userDimensions: UserDimensions =
      await this.gaHelper.getUserDimensions(user);

    if (gaEvent.toLowerCase().includes("dashboard")) {
      values = Object.assign(values, {
        ...userDimensions,
      });
    } else {
      const Contract_number = this.project.selectedProject?.projectNumber;
      const user = await this.auth.getUser();
      const userDimensions: UserDimensions =
        await this.gaHelper.getUserDimensions(user);

      values = Object.assign(values, {
        ...(Contract_number && { Contract_number }),
        ...userDimensions,
      });
    }

    const filterApi = this.filter.filterApi;

    switch (gaEvent) {
      case GA_EVENTS.DOWNLOAD_RM_ALL:
        if (filterApi.isAnyFilterApplied) {
          gaOptions = GA_EVENT_OPTIONS[GA_EVENTS.DOWNLOAD_RM_FILTER];
          const appliedFilters = filterApi.appliedFiltersAsObject;
          for (const id in appliedFilters) {
            appliedFilters[id] = true;
          }
          values = Object.assign(values, appliedFilters);
        }
        break;
      case GA_EVENTS.DOWNLOAD_DISPATCH_ALL:
        if (filterApi.isAnyFilterApplied) {
          gaOptions = GA_EVENT_OPTIONS[GA_EVENTS.DOWNLOAD_DISPATCH_FILTER];
          const appliedFilters = filterApi.appliedFiltersAsObject;
          for (const id in appliedFilters) {
            appliedFilters[id] = true;
          }
          values = Object.assign(values, appliedFilters);
        }
        break;
      default:
        break;
    }
    var gaOptions = gaOptions ? gaOptions : GA_EVENT_OPTIONS[gaEvent];
    this.dispatchGAEvent({ ...gaOptions, values });
  }

  findGAEventByActionAndLabel(eventAction, eventLabel) {
    let gaEvent;
    for (const [key, value] of Object.entries(GA_EVENT_OPTIONS)) {
      if (value.action === eventAction && value.event_label === eventLabel) {
        gaEvent = key;
      }
    }
    return gaEvent;
  }

  async handleGAForHTMLElement(element: HTMLElement, actualEventName: string) {
    const attributes = this.dom.getTrackedDataAttributesWithValues(element);
    if (attributes["ga_event"]) {
      const gaEvent = attributes["ga_event"];
      delete attributes["ga_event"];
      const values = { ...attributes };
      await this.sendGAEvent(gaEvent, values);
      return;
    }
    let eventAction: string = attributes["event_action"];
    let eventLabel: string = attributes["event_label"];
    eventAction = eventAction.replace("-", "_");
    eventLabel = eventLabel.replace("-", "_");
    const gaEvent = this.findGAEventByActionAndLabel(eventAction, eventLabel);
    delete attributes["event_action"];
    delete attributes["event_label"];
    const values = { ...attributes };
    this.sendGAEvent(gaEvent, values);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
