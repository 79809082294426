import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Contains the list of common modules
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxMasonryModule } from 'ngx-masonry';
import { CdkTableModule } from '@angular/cdk/table';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderFullScreenComponent } from './components/loader-full-screen/loader-full-screen.component';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { ImageThumbnailListComponent } from './components/image-thumbnail-list/image-thumbnail-list.component';
import { ImageThumbnailComponent } from './components/image-thumbnail/image-thumbnail.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { InputComponent } from './components/input/input.component';
import { FileViewerModalComponent } from './components/file-viewer-modal/file-viewer-modal.component';
//Ngx Bootstrap Modules

import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { PaymentSummaryToastComponent } from './components/payment-summary-toast/payment-summary-toast.component';
import { PdfViewerComponent, PdfViewerConfig, setPdfViewerConfig } from './components/pdf-viewer/pdf-viewer.component';
import { PdfViewerModule, RenderTextMode } from 'ng2-pdf-viewer';
import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { DifferenceBetweenDatesPipe } from '../@core/pipes/difference-between-dates.pipe';
import { TotalMeasureComponent } from '../drawings/total-measure/total-measure.component';
import { FilterModule } from './filter/filter.module';
import { FilterService } from './filter/services/filter.service';
import { TableModule } from './table/table.module';
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        CarouselModule.forRoot(),
        BsDropdownModule.forRoot(),
        TabsModule.forRoot(),
        PopoverModule.forRoot(),
        ScrollingModule,
        InfiniteScrollModule,
        PipesModule,
        DirectivesModule
    ],
    declarations: [
        LoaderComponent,
        ProgressCircleComponent,
        ImageThumbnailListComponent,
        ImageThumbnailComponent,
        ImageCarouselComponent,
        LoaderFullScreenComponent
    ],
    exports: [
        /* Common Modules */
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        CarouselModule,
        TabsModule,
        ScrollingModule,
        InfiniteScrollModule,
        CdkTableModule,
        PopoverModule,
        /* Components */
        ProgressCircleComponent,
        LoaderComponent,
        ImageThumbnailListComponent,
        ImageThumbnailComponent,
        ImageCarouselComponent,
        LoaderFullScreenComponent,
        PipesModule,
        DirectivesModule
    ],
})
class CommonsModule { }

@NgModule({
    imports: [
        CommonModule,
        CommonsModule
    ],
    declarations: [
    ],
    exports: [
        CommonModule,
        CommonsModule
    ],
    providers: []
})
export class SharedMobileModule { }


@NgModule({
    imports: [
        CommonModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        AccordionModule.forRoot(),
        NgxMasonryModule,
        CommonsModule,
        PdfViewerModule,
        FilterModule,
        TableModule,
    ],
    declarations: [
        PaymentSummaryToastComponent,
        FileViewerComponent,
        PdfViewerComponent,
        DifferenceBetweenDatesPipe,
        InputComponent,
        TotalMeasureComponent,
        // AttachmentIconComponent,
        FileViewerModalComponent,
    ],
    exports: [
        CommonModule,
        TooltipModule,
        ModalModule,
        AccordionModule,
        CommonsModule,
        PdfViewerModule,
        FilterModule,
        TableModule,
        NgxMasonryModule,
        PdfViewerComponent,
        PaymentSummaryToastComponent,
        FileViewerComponent,
        DifferenceBetweenDatesPipe,
        InputComponent,
        TotalMeasureComponent,
        // AttachmentIconComponent,
        FileViewerModalComponent,
    ],
    providers: [
        {
            provide: PdfViewerConfig,
            useValue: setPdfViewerConfig({
                'stick-to-page': true,
                'external-link-target': 'blank',
                'render-text': false,
                'render-text-mode': RenderTextMode.DISABLED,
                'rotation': 0,
                'zoom': 1,
                'original-size': false,
                'fit-to-page': true,
                'show-all': true,
                'autoresize': true,
                'show-borders': true
            })
        },
        // SumPipe,
    ],
    entryComponents: [
        TotalMeasureComponent
    ]
})
export class SharedModule { }
