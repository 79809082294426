<div class="pdf-viewer-container">
  <pdf-viewer
  class="pdf-viewer"
  [src]="this['src']"
  [page] = "this['page']"
  [stick-to-page] = "this['stick-to-page']"
  [external-link-target] = "this['external-link-target']"
  [render-text] = "this['render-text']"
  [render-text-mode] = "this['render-text-mode']"
  [rotation] = "this['rotation']"
  [zoom] = "this['zoom']"
  [original-size] = "this['original-size']"
  [fit-to-page] = "this['fit-to-page']"
  [show-all] = "this['show-all']"
  [autoresize] = "this['autoresize']"
  [c-maps-url] = "this['c-maps-url']"
  [show-borders] = "this['show-borders']"
  (after-load-complete)="afterLoadComplete($event)"
  (on-progress)="onProgress($event)">
</pdf-viewer>

  <div class="viewer-actions">
    <div class="file-name">{{fileName}}</div>
    <div class="zoom">
      <i class="fa fa-plus-circle cursor" (click)="zoomIn()" aria-hidden="true"></i>
      <span>{{zoom * 100 }}%</span> 
      <i class="fa fa-minus-circle cursor" (click)="zoomOut()" aria-hidden="true"></i>
    </div>
    <div class="page-number">
      <i class="fa fa-chevron-circle-left cursor" (click)="prevPage()" aria-hidden="true"></i>
      <span class="count">{{page}}/{{totalPages}}</span>
      <i class="fa fa-chevron-circle-right cursor" (click)="nextPage()" aria-hidden="true"></i>
    </div>
  </div>

  <div class="center-full" [class.hidden]="loaded">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>