import { HostListener, Injectable } from '@angular/core';
import * as localForage from 'localforage';

interface UTMParams {
  utm_source: string; // user's email
  utm_source_id: string; // users's unique _id
  utm_medium: string; // Mailer/Whatsapp
}

@Injectable({
  providedIn: 'root'
})
export class GAHelperService {
  constructor() { }

  async setUTMParams(utmUrl) {
    const queryString = utmUrl.substring(utmUrl.search('\\?') + 1);
    const params = new URLSearchParams(queryString);
    const utmParams: UTMParams = {
      utm_source : params.get('utm_source'),
      utm_source_id : params.get('utm_source_id') || 'N/A',
      utm_medium : params.get('utm_medium')
    };
    
    await localForage.setItem('utmData', utmParams);
  }

  async unsetUTMParams() {
    await localForage.setItem('utmData', {
      utm_source: 'N/A',
      utm_source_id: 'N/A',
      utm_medium: 'N/A'
    });
  }

  async getUserDimensions(user) {
    const {utm_medium, utm_source, utm_source_id} = await this.getUTMData();
    const user_id = user? user._id : utm_source_id;
    const email = user? user.email : utm_source;
    const user_group = email.toLowerCase().includes('zetwerk') ? 'Zetwerk Users': 'External Users';

    return {
      User_identifier: user_id,
      User_group: user_group,
      UTM_medium: utm_medium
    }
  }

  async getUTMData() {
    let utmData:any = await localForage.getItem('utmData');
    if(!utmData) {
      utmData = {
        utm_source: 'N/A',
        utm_source_id: 'N/A',
        utm_medium: 'N/A'
      }
    }
    return utmData;
  }
}
