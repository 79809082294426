import { NgModule, APP_INITIALIZER, LOCALE_ID, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './@shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderInterceptor } from './@core/interceptors/loader.interceptor';
import { projectsProviderFactory, ProjectsService } from './@core/services/projects.service';
import { AuthInterceptor } from './@core/interceptors/auth.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { FilteredEventService } from './@core/services/filtered-event.service';
import { EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { FilteredEventPlugin } from './@core/services/filtered-event.plugin';
import { authProviderFactory, AuthService } from './@core/authentication/auth.service';
import { MapViewLayoutComponent } from './map-view/map-view-layout/map-view-layout.component';
@NgModule({
   declarations: [
      AppComponent,
      MapViewLayoutComponent
   ],
   imports: [
      AppRoutingModule,
      SharedModule,
      BrowserAnimationsModule,
      OverlayModule
   ],
   providers: [
      {
         provide: HTTP_INTERCEPTORS,
         useClass: AuthInterceptor,
         multi: true
      },
      {
         provide: HTTP_INTERCEPTORS,
         useClass: LoaderInterceptor,
         multi: true
      },
      {
         provide: APP_INITIALIZER,
         useFactory: projectsProviderFactory,
         deps: [ProjectsService],
         multi: true
      },
      {
         provide: APP_INITIALIZER,
         useFactory: authProviderFactory,
         deps: [AuthService],
         multi: true
      },
      { provide: LOCALE_ID, useValue: 'en-IN' },
      { provide: DEFAULT_CURRENCY_CODE, useValue: 'INR' },
      FilteredEventService,
      {
         provide: EVENT_MANAGER_PLUGINS,
         useClass: FilteredEventPlugin,
         multi: true,
      }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
