import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { timer } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { ThemeService } from '../services/theme.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectThemeGuard implements CanActivate {


  constructor(
    private router: Router,
    private theme: ThemeService,
    private _notification: NotificationService
  ) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.theme.loadStyle('project.css');
    await timer(500).toPromise()
    return true;
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this._notification.syncTrackingId(route);
    this.theme.loadStyle('project.css');
    await timer(1000).toPromise()
    return true;
  }


}
