import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxFilterComponent } from './components/checkbox-filter/checkbox-filter.component';
import { RadioFilterComponent } from './components/radio-filter/radio-filter.component';
import { MultiselectFilterComponent } from './components/multiselect-filter/multiselect-filter.component';
import { DropdownFilterComponent } from './components/dropdown-filter/dropdown-filter.component';
import { FilterListComponent } from './components/filter-list/filter-list.component';
import { AbstractFilterComponent } from './abstract-filter.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule
  ],
  declarations: [
    AbstractFilterComponent,
    CheckboxFilterComponent,
    RadioFilterComponent,
    MultiselectFilterComponent,
    DropdownFilterComponent,
    FilterListComponent,
  ],
  exports: [
    CheckboxFilterComponent,
    RadioFilterComponent,
    MultiselectFilterComponent,
    DropdownFilterComponent,
    FilterListComponent
  ]
})
export class FilterModule { }
