import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ThemeService } from '../services/theme.service';
import { timer } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { NotificationService } from '../services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardModuleGuard implements CanActivate {


  constructor(
    private router: Router,
    private theme: ThemeService,
    private loaderService: LoaderService,
    private _notification: NotificationService
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    this._notification.syncTrackingId(route);
    if (!this.theme.lastStyleNameLoaded) {
      this.theme.loadStyle('responsive.css');
      await timer(500).toPromise()
    }
    return true;
  }

}
