import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as localForage from 'localforage';
import { of, Observable } from 'rxjs';

const API_URL = environment.apiEndPointV2;

export function projectsProviderFactory(provider: ProjectsService) {
  return () => provider.load();
}
@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  selectedProject;
  constructor(private http: HttpClient) { }

  load() {
    return new Promise((resolve, reject) => {
      this.getSelectedProject().then(() => {
        resolve(this.selectedProject);
      });
    });
  }

  getProjectById(projectId, email): Observable<any> {
    return this.http.get(`${API_URL}projects/${projectId}/customer-details`, {
      params: {
        ...email && { email }
      }
    });
  }

  async setSelectedProject(project) {
    this.selectedProject = project;
    await localForage.setItem('selectedProject', this.selectedProject);
  }

  async getSelectedProject() {
    if (!this.selectedProject) {
      this.selectedProject = await localForage.getItem('selectedProject');
    }
    return this.selectedProject;
  }

  async getSelectedProjectConfig() {
    const project = await this.getSelectedProject();
    return project && project.projectConfig;
  }

  async isLotLevelTrackingProject() {
    const { levelOfTracking } = await this.getSelectedProjectConfig();
    return levelOfTracking && levelOfTracking === 'LOT';
  }

  /* http://localhost:3000/v2/lot-progress-report */
  downloadLotProgessReport(projectId) {
    return this.http.get(`${API_URL}projects/${projectId}/lot-progress-report`);
  }

  getProgressSummary(projectId) {
    return this.http.get(`${API_URL}projects/${projectId}/progress-summary`);
  }

  getStatusCardSummary(projectId) {
    return this.http.get(`${API_URL}customer-portal/projects/${projectId}/status-card-summary`);
  }

  getAllVariants(projectId) {
    return this.http.get(`${API_URL}projects/${projectId}/serial-production/marks`);
  }

  /*
  http://localhost:3000/v2/projects/5d31efc2fd806233f31b18fd/timelines
   */
  getDispatchTimelineForProject(projectId) {
    return this.http.get(`${API_URL}projects/${projectId}/timelines`);
  }

  getProjectConfig(projectId): Observable<any> {
    return this.http.get(`${API_URL}customer-portal/projects/${projectId}/project-config`);
  }
}
