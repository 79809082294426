import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { zip, of, Subject, combineLatest } from 'rxjs';
import { map, catchError, tap, startWith } from 'rxjs/operators';
import { AuthService } from 'src/app/@core/authentication/auth.service';
import { LoaderService } from 'src/app/@core/services/loader.service';
import * as localForage from 'localforage';

const API_URL = environment.apiEndPointV2;

@Injectable({
  providedIn: 'root'
})

export class DashboardService {
  customerSummaryData: Subject<any> = new Subject();
  customerMeta;
  meta = {
    customerName: undefined
  };

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private loaderService: LoaderService
  ) { }

  async resolveCustomerSummary() {
    await this.getCustomerName();
    const result: any = await new Promise(async (resolve, reject) => {
      const meta$ = this.getCustomerMeta().pipe(startWith(undefined), map((res: any) => res?.data));
      const upcomingDispatches$ = this.getUpcomingDispatches().pipe(startWith(undefined), map((res: any) => res?.data));
      const projectDocuments$ = this.getProjectDocuments().pipe(startWith(undefined), map((res: any) => res?.data));
      const frequentyViewedProjects$ = this.getFrequentlyViewedProjects().pipe(startWith(undefined), map((res: any) => res?.data));
      const paymentsDue$ = this.getPaymentsDue({ pageNumber: 1, recordsPerPage: 5 }).pipe(startWith(undefined), map((res: any) => res?.data));

      const source$ = combineLatest(meta$, upcomingDispatches$, projectDocuments$, frequentyViewedProjects$, paymentsDue$);
      source$.pipe(
        tap(([meta, upcomingDispatches, projectDocuments, frequentyViewedProjects, paymentsDueData]) => {
          this.customerSummaryData.next({ meta, upcomingDispatches, projectDocuments, frequentyViewedProjects, paymentsDueData });
        })).subscribe(([meta, upcomingDispatches, projectDocuments, frequentyViewedProjects, paymentsDueData]) => {
          resolve({ meta, upcomingDispatches, projectDocuments, frequentyViewedProjects, paymentsDueData });
        });
    });
    this.customerMeta = result.meta;
    return result;
  }


  getCustomerMeta() {
    return this.http.get(`${API_URL}customer-portal/dashboard/customer-meta`)
      .pipe(
        catchError((err) => this.catchError(err))
      );
  }

  getUpcomingDispatches() {
    return this.http.get(`${API_URL}customer-portal/dashboard/upcoming-dispatches`)
      .pipe(
        catchError((err) => this.catchError(err))
      );
  }

  getProjectDocuments() {
    return this.http.get(`${API_URL}customer-portal/dashboard/document-summary`)
      .pipe(
        catchError((err) => this.catchError(err))
      );
  }

  getFrequentlyViewedProjects() {
    return this.http.get(`${API_URL}customer-portal/dashboard/frequent-projects`)
      .pipe(
        catchError((err) => this.catchError(err))
      );
  }

  getPaymentsDue({ ...params }) {
    return this.http.get(`${API_URL}customer-portal/dashboard/payments-due-summary`, {
      params
    }).pipe(
      catchError((err) => this.catchError(err))
    );
  }

  getProjects({ status, pageIndex, recordsPerPage, searchText = '' }) {
    const params = { status, pageNumber: pageIndex, recordsPerPage, searchText }
    return this.http.get(`${API_URL}customer-portal/dashboard/projects`, {
      params
    }).pipe(
      catchError((err) => this.catchError(err))
    );
  }

  async checkUserLoggedIn() {
    const token = await this.auth.getAuthToken();
    const result: boolean = await new Promise((resolve) => {
      if (!token) {
        resolve(false);
        return;
      }
      this.getCustomerMeta()
        .pipe(
          tap(async (res: any) => await this.updateDashboardMeta({ customerMeta: res.data })),
          map((res: any) => res.success),
        )
        .subscribe((res) => {
          if (!res) {
            resolve(false);
            return;
          }
          resolve(res);
          return;
        },
          (err) => {
            resolve(false);
            return;
          });
    });
    return result;
  }

  async catchError(err) {
    if (err.status === 401) {
      alert('Auth Token is Invalid! Please login to continue.');
      this.auth.logout();
      window.open('/dashboard', '_self');

    }
    return of(err);
  }

  async updateDashboardMeta({ customerMeta }) {
    if (customerMeta) {
      const customerName = customerMeta.customerName;
      this.meta.customerName = customerName;
      await localForage.setItem('customerName', customerName);
    }
  }

  async getCustomerName() {
    if (this.meta.customerName) {
      return this.meta.customerName
    } else {
      this.meta.customerName = await localForage.getItem('customerName');
      return this.meta.customerName;
    }

  }


}
