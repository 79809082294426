import { Pipe, PipeTransform } from '@angular/core';
import { isArray } from 'util';
import { sum } from 'src/app/@core/utils/utils';


@Pipe({ name: 'sum' })
export class SumPipe implements PipeTransform {
  transform(input: any, key?: string): any {
    if (!isArray(input)) return input;
    let mappedInput = input;
    if (key) mappedInput = mappedInput.map((input) => input[key]);
    return  sum(mappedInput);
  }
}