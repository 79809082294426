import { Pipe, PipeTransform } from '@angular/core';
import { UnitValue, TotalMeasure, isTotalMeasure, isUnitValue, isUnitValueArray, MEASUREMENT_TYPES } from '../../models/measure';


@Pipe({
  name: 'totalMeasure'
})
export class TotalMeasurePipe implements PipeTransform {

  getUnitValueString(unitValue: UnitValue) {
    let str = '';
    if (!unitValue.value) {
      return;
    }
    unitValue.value = Number(unitValue.value.toFixed(4));
    if (unitValue.unit) {
      str += String(unitValue.value) ? String(unitValue.value) : '0';
      str += ` ${unitValue.unit.toUpperCase()}`;
    } else {
      str += String(unitValue.value) ? String(unitValue.value) : '0';
    }
    return str;
  }

  totalMeasureToUnitValueArray(measure: TotalMeasure) {
    const measureArray = [];
    let measureKeys: any = Object.keys(measure);
    measureKeys = measureKeys.map(function mapKeyToMeasurementTypeEnum(key) { return { key, priority: MEASUREMENT_TYPES[key] } });
    measureKeys = measureKeys.sort((a, b) => a.priority - b.priority).map((a) => a.key);
    for (const type of measureKeys) {
      measureArray.unshift(measure[type]);
    }
    return measureArray;
  }

  stringReducer(item, separator, str) {
    const unitValueString = this.getUnitValueString(item);
    if (unitValueString && unitValueString.length) {
      return unitValueString + separator + ' ' + str;
    }
    return str;
  }

  transform(value: TotalMeasure | UnitValue[] | UnitValue | number, separator: string = ',', args?: any): any {
    if (!value) {
      return;
    }
    let measure = JSON.parse(JSON.stringify(value));
    if (typeof measure === 'number') {
      measure = measure + ' NO.';
    } else if (typeof measure === 'object' && isUnitValue(measure)) {
      measure = this.getUnitValueString(measure);
    } else if (typeof measure === 'object' && isUnitValueArray(measure)) {
      measure = (measure as any).reduce((str, item) => this.stringReducer(item, separator, str), '');
    } else if (typeof measure === 'object' && isTotalMeasure(measure)) {
      measure = this.totalMeasureToUnitValueArray(measure);
      measure = (measure as any).reduce((str, item) => this.stringReducer(item, separator, str),  '');
    } else if (typeof measure === 'object' && Object.keys(measure).length === 0) {
      measure = '';
    } else {
      throw new Error('Invalid argument passed to totalMeasure pipe');
    }
    measure = measure.replace(/(^,)|(, $)/g, '').trim();
    return  (measure && measure.length && measure) || 0;
  }

}
