import { Routes } from '@angular/router';
import { isMobile } from './@core/utils/device';
import { ProjectThemeGuard } from 'src/app/@core/guards/project-theme.guard';
import { DashboardModuleGuard } from './@core/guards/dashboard-module.guard';
import { MapViewGuard } from './@core/guards/map-view.guard';
import { MapViewLayoutComponent } from './map-view/map-view-layout/map-view-layout.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./notification/notification.module').then(
        (m) => m.NotificationModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [DashboardModuleGuard],
    loadChildren: () =>
      import('./v2/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: ':projectId',
    canActivate: [ProjectThemeGuard],
    loadChildren: () =>
      isMobile()
        ? import('./mobile-view/mobile-view.module').then(
            (m) => m.MobileViewModule
          )
        : import('./project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'project-details',
    component: MapViewLayoutComponent,
    loadChildren: () => import('./map-view-wrapper/map-view-wrapper.module').then((m) => m.MapViewWrapperModule)
  }
];
