import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/@core/services/loader.service';

@Component({
  selector: 'zico-loader-full-screen',
  templateUrl: './loader-full-screen.component.html',
  styleUrls: ['./loader-full-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderFullScreenComponent implements OnInit {

  private subscription: Subscription;
  show = false;

  constructor(
    private loaderService: LoaderService,
    private _cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe(state => {
        this.show = state.show;
        this._cdRef.detectChanges();
      });
    this.loaderService.initialLoadCompleted.subscribe(status => {
      if (status) {
        this.show = false;
        this._cdRef.detectChanges();
        this.destroySubscription();
      }
    });
  }

  destroySubscription() {
    this.subscription.unsubscribe();
  }

}
