import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { tap, catchError } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.auth.authToken;
    let authReq;
    if (authToken) {
      authReq = req.clone({
        headers: req.headers.set('authorizationtoken', authToken)
      });
    } else {
      authReq = req.clone({
      });
    }

    return next.handle(authReq).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          // Http request resolves
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}
