export interface IAttachment {
    fileName: string;
    path: string;
    fileType: string;
}

export const MIMETypes = {
    'application/pdf': 'pdf',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'text/csv': 'csv',
    'application/rtf': 'rtf',
    'text/plain': 'txt',
    'application/zip': 'zip',
    'image/png': 'png',
    'image/jpg': 'jpg',
    'image/jpeg': 'jpeg',
    'image/gif': 'gif',
    'image/svg+xml': 'svg',
    'image/vnd.adobe.photoshop': 'psd',
    'audio/mpeg': 'mp3',
    'application/mp4': 'mp4',
    'video/x-msvideo': 'avi'
};

export const ImageExtensions = ['png', 'jpg', 'jpeg'];

