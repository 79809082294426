export enum DATA_ATTRIBUTES_TRACKED {
    ga_event = 'ga-event',
    event_action = 'event-action',
    event_label = 'event-label',
    Invoice_number = 'Invoice_number',
    Widget_id = 'Widget_id',
    File_type = 'File_type',
}

export function customDimensionMapper(startIndex: number, dimensionIndexStart: number) {
  const attributesTracked = Object.keys(DATA_ATTRIBUTES_TRACKED);
  const customDimensions = {};

  for (let index = startIndex; index < attributesTracked.length; index++) {
    const dimension = "dimension";
    const dimensionValue = DATA_ATTRIBUTES_TRACKED[attributesTracked[index]];
    customDimensions[`${dimension}${dimensionIndexStart}`] = dimensionValue;
    dimensionIndexStart++;
  }
  return customDimensions;
}
  