import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

export const endpointsWithoutLoader = [
  'customer-email-verification',
  'authenticate-by-otp',
  'request-email-otp'
];

@Injectable({
  providedIn: 'root'
})
export class NoLoaderEndPoints {
  endPoints: Array<string>;
}

export function setNoLoaderEndPoints(config) {
  return {
    endPoints: [...config.endPoints]
  };
}

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {

  noLoaderEndPoints = [];

  constructor(
    private loaderService: LoaderService,
    private noLoader: NoLoaderEndPoints
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (endpointsWithoutLoader?.some(endPoint => req?.url?.includes(endPoint))) {
      return next.handle(req);
    }
    this.showLoader();
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
      },
        (err: any) => {
          this.onEnd();
        }));
  }
  private onEnd(): void {
    this.hideLoader();
  }
  private showLoader(): void {
    this.loaderService.show();
  }
  private hideLoader(): void {
    this.loaderService.hide();
  }
}
