import { Component, OnInit, ChangeDetectionStrategy, Injectable, Input, ChangeDetectorRef } from '@angular/core';
import { RenderTextMode, PDFProgressData, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PdfViewerConfig {
  src: string;
  page: number = 1;
  'stick-to-page': boolean;
  'external-link-target': string;
  'render-text': boolean;
  'render-text-mode': RenderTextMode;
  'rotation': number;
  'zoom': number;
  'original-size': boolean;
  'fit-to-page': boolean;
  'show-all': boolean;
  'autoresize': boolean;
  'c-maps-url': string;
  'show-borders': boolean;
}

export function setPdfViewerConfig(config: Partial<PdfViewerConfig>) {
  return {
    ...new PdfViewerConfig(),
    ...config,
  }
}
@Component({
  selector: 'zico-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent implements OnInit {
  @Input() src = PdfViewerConfig['src'];
  @Input() page = PdfViewerConfig['page'];
  @Input() 'stick-to-page' = PdfViewerConfig['stick-to-page'];
  @Input() 'external-link-target' = PdfViewerConfig['external-link-target'];
  @Input() 'render-text' = PdfViewerConfig['render-text'];
  @Input() 'render-text-mode' = PdfViewerConfig['render-text-mode'];
  @Input() 'rotation' = PdfViewerConfig['rotation'];
  @Input() 'zoom' = PdfViewerConfig['zoom'];
  @Input() 'original-size' = PdfViewerConfig['original-size'];
  @Input() 'fit-to-page' = PdfViewerConfig['fit-to-page'];
  @Input() 'show-all' = PdfViewerConfig['show-all'];
  @Input() 'autoresize' = PdfViewerConfig['autoresize'];
  @Input() 'c-maps-url' = PdfViewerConfig['c-maps-url'];
  @Input() 'show-borders' = PdfViewerConfig['show-borders'];
  @Input() fileName;
  loaded: boolean = false;
  totalPages = 1;
  constructor(private config: PdfViewerConfig, private cd: ChangeDetectorRef) {
    this['src'] = config['src'];
    this['page'] = config['page'];
    this['stick-to-page'] = config['stick-to-page'];
    this['external-link-target'] = config['external-link-target'];
    this['render-text'] = config['render-text'];
    this['render-text-mode'] = config['render-text-mode'];
    this['rotation'] = config['rotation'];
    this['zoom'] = config['zoom'];
    this['original-size'] = config['original-size'];
    this['fit-to-page'] = config['fit-to-page'];
    this['show-all'] = config['show-all'];
    this['autoresize'] = config['autoresize'];
    this['c-maps-url'] = config['c-maps-url'];
    this['show-borders'] = config['show-borders'];
  }

  ngOnInit() {
    this.cd.detectChanges();
    interval(1000).pipe(take(5)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  onProgress(progressData: PDFProgressData) {
    // do anything with progress data. For example progress indicator
    if (progressData.loaded === progressData.total) {
      this.loaded = true;
      this.cd.detectChanges();
    }
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    console.log('after load complete', pdf);
    this.totalPages = pdf.numPages;
  }

  nextPage() {
    this["show-all"] = false;
    if (this.page < this.totalPages) {
      this.page++;
    }
  }

  prevPage() {
    this["show-all"] = false;
    if (this.page > 1) {
      this.page--;
    }
  }

  zoomIn(){
    this.zoom = this.zoom + 0.25;
  }

  zoomOut() {
    this.zoom = this.zoom - 0.25;
  }

}
