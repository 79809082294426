import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'zico-map-view-layout',
  templateUrl: './map-view-layout.component.html',
  styleUrls: ['./map-view-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapViewLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
