export interface UnitValue {
    unit: string;
    value: number;
}

export interface TotalMeasure {
    [key: string]: UnitValue;
}

export const isTotalMeasure = (variableToCheck: any): variableToCheck is TotalMeasure =>
    (variableToCheck as TotalMeasure).unit === undefined && Object.keys(variableToCheck).length > 0;

export const isUnitValue = (variableToCheck: any): variableToCheck is UnitValue =>
    (variableToCheck as UnitValue).unit !== undefined;


export const isUnitValueArray = (variableToCheck: any): variableToCheck is UnitValue =>
    Array.isArray(variableToCheck) && variableToCheck.length && (variableToCheck[0] as UnitValue).unit !== undefined;


export enum MEASUREMENT_TYPES {
    weight,
    length,
    volume,
    area,
    number,
    set,
    roll,
    pair,
    box,
    packet
}
