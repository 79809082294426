import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/@core/services/loader.service';
import { LoaderState } from '../../models/loader';

@Component({
  selector: 'zico-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnDestroy {

  show = false;
  private subscription: Subscription;
  constructor(private loaderService: LoaderService, private _cdRef: ChangeDetectorRef) {
  }
  ngOnInit() {
    this.subscription = this.loaderService.loaderState
    .subscribe((state: LoaderState) => {
      this.show = state.show;
      this._cdRef.detectChanges();
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
