import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { FilterApi } from '../models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  _clearAllFilters: Subject<boolean> = new Subject();
  _clearFilter: Subject<string> = new Subject();
  filterApi: FilterApi;
  constructor() { }

  clearFilter(id) {
    this._clearFilter.next(id);
  }

  clearAllFilters() {
    this._clearAllFilters.next(true);
  }

}
