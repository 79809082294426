import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from 'src/environments/environment';
const API_URL = environment.apiEndPointV2;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  unsubscribePreference(projectId, userId) {
    return this.http.get(`${API_URL}notification/${projectId}/unsubscribe`, {
      params: {
        userId
      }
    });
  }

  subscribePreference(projectId, userId) {
    return this.http.get(`${API_URL}notification/${projectId}/subscribe`, {
      params: {
        userId
      }
    });
  }

  async syncTrackingId(route) {
    const trackingId = route.queryParamMap.get('trackingId');
    if (trackingId) {
      this.syncWhatsappMessageClick(trackingId).subscribe();
    }
  }

  syncWhatsappMessageClick(trackingId) {
    return this.http.get(`${API_URL}notification/tracking/${trackingId}`);
  }
}
