import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesComponent } from './pipes.component';
import { TotalMeasurePipe } from './pipes/total-measure.pipe';
import { SumPipe } from './pipes/sum.pipe';
import { IndianCurrency } from './pipes/indian-currency.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PipesComponent,
    TotalMeasurePipe,
    SumPipe,
    IndianCurrency
  ],
  providers: [
    TotalMeasurePipe,
    SumPipe,
    IndianCurrency
  ],
  exports: [
    TotalMeasurePipe,
    SumPipe,
    IndianCurrency
  ]
})
export class PipesModule { }
