import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  lastStyleNameLoaded = undefined;
  constructor(@Inject(DOCUMENT) private document: Document) { }

  loadStyle(styleName: string) {
    if (!this.lastStyleNameLoaded || this.lastStyleNameLoaded !== styleName) {
      this.lastStyleNameLoaded = styleName
    } else if (this.lastStyleNameLoaded === styleName) {
      return;
    }
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
}
