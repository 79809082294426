// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  isCustomerPortal: true,
  appUrl: 'https://staging-customer.zetwerk.com',
  apiEndPoint: 'https://api.staging.zetwerk.com/ziso/v1/',
  apiEndPointV2: 'https://api.staging.zetwerk.com/ziso/v2/',
  authApiEndPoint: 'https://api.staging.zetwerk.com/v1/',
  googleAnalyticsId: 'UA-125319618-12',
  mixpanelToken: '86dddb19bc1c165f3d0d81b136963b82'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
