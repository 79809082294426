import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import { Router, ActivatedRoute, RouterEvent, NavigationStart } from '@angular/router';
import { ProjectsService } from '../services/projects.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GAHelperService } from '../services/ga-helper.serice';
import { MixpanelService } from '../services/mixpanel.service';

declare var gtag: Function;

interface GAUserDimensions {
  User_identifier: string,
  User_group: string,
  UTM_medium: string
}
export function authProviderFactory(provider: AuthService) {
  return async () => {
    await provider.load()
  };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authToken;
  user;
  gAUserDimensions: GAUserDimensions;
  subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private project: ProjectsService,
    private gaHelper: GAHelperService,
    private _mixpanelService: MixpanelService
  ) {
    this.getUser().then(() =>  {})
    this.parseUrl();
  }

  async load() {
    let user = await this.getUser();
    let gaUserDimensions = await this.gaHelper.getUserDimensions(user)
    this.gAUserDimensions = gaUserDimensions;
  }

  async parseUrl() {
    this.subscription.add(this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationStart))
      .subscribe(async (event: NavigationStart) => {
        const url = event.url;
        if(url.includes('utm_source')) {
          this.gaHelper.setUTMParams(url);
        }
      })
    );
    this.gAUserDimensions = await this.gaHelper.getUserDimensions(this.user);
  }

  async setAuthToken(token) {
    this.authToken = token;
    return localForage.setItem('authToken', token);
  }

  async getAuthToken() {
    if (!this.authToken) {
      this.authToken = await localForage.getItem('authToken');
    }
    return this.authToken;
  }

  async setUser(user) {
    this.user = user;
    this.gAUserDimensions = await this.gaHelper.getUserDimensions(user);
    gtag("set", this.gAUserDimensions);
    return await localForage.setItem('user', user);
  }

  async getUser() {
    if (!this.user) {
      this.user = await localForage.getItem('user');
    }
    return this.user;
  }

  clearCache() {
    this.setUser(null);
    this.setAuthToken(null);
  }

  async logoutFromPayments() {
    alert('Auth Token is Invalid! Please login to continue.');
    this.clearCache();
    const projectId = this.project.selectedProject?._id;
    await localForage.clear();
    this._mixpanelService.clearData();
    this.router.navigate([projectId, 'payments', 'login']);
  }

  async logout() {
    this.clearCache();
    await localForage.clear();
    this._mixpanelService.clearData();
  }
}
