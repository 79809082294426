import { Injectable, isDevMode } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import * as localForage from 'localforage';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() { }

  init(): void {
    mixpanel.init(environment.mixpanelToken, { debug: isDevMode() })
  }

  async setUserProfile(): Promise<void> {
    const user = await localForage.getItem('user');
    if (!user) return;
    const {email, firstName, lastName}: any = user;
    mixpanel.identify(email);
    mixpanel.people.set({
      '$email': email,
      '$name': `${firstName} ${lastName}`
    });
  }

  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }

  clearData(): void {
    mixpanel.reset();
  }

  async getPageViewPayload(currentUrl: string): Promise<any> {
    const user = await localForage.getItem('user');
    if (!user) return null;
    const {id: zUserId}: any = user;
    let zPreviousUrl: string = localStorage.getItem('previousUrl') || '';
    const {url: zEventUrl, zUUID} = this.classifyUrl(currentUrl.split('/'));

    if (zPreviousUrl) {
      ({ url: zPreviousUrl } = this.classifyUrl(localStorage.getItem('previousUrl').split('/')));
    }

    return {
      zEventUrl,
      ...(zUserId ? { zUserId } : {}),
      ...(zPreviousUrl ? { zPreviousUrl } : {}),
      ...(zUUID ? { zUUID } : {})
    }
  }

  classifyUrl(urlArr: Array<string>): any {
    let zUUID: string = '';
    const finalUrlArr: Array<string> = [];
    const alphabetRegex: RegExp = new RegExp('^[a-zA-Z-]+$');

    urlArr.forEach((urlSegment: string, index: number) => {
      if (index === urlArr.length - 1) urlSegment = urlSegment.split('?')[0]; // Remove query params

      if (alphabetRegex.test(urlSegment)) {
        finalUrlArr.push(urlSegment);
      } else if (urlSegment.match(/^[0-9a-fA-F]{24}$/) && !zUUID) { // Match for MongoDB ObjectID
        zUUID = urlSegment;
      }
    })

    return {
      url: finalUrlArr.join('/'),
      zUUID
    }
  }
}
