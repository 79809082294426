import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { TableComponent } from './table.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { AttachmentIconComponent } from '../components/attachment-icon/attachment-icon.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    PipesModule
  ],
  declarations: [
      TableComponent,
      TableCellComponent,
      AttachmentIconComponent,
      ClickStopPropagationDirective
  ],
  exports: [
    TableComponent,
    AttachmentIconComponent
  ]
})
export class TableModule { }
