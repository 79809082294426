import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ProjectsService } from './@core/services/projects.service';
import { GAnalyticsService } from './@core/services/g-analytics.service';
import { NavigationEnd, Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { filter, pairwise, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ThemeService } from './@core/services/theme.service';
import * as localForage from 'localforage';
import { MixpanelService } from './@core/services/mixpanel.service';
(window as any).pdfWorkerSrc = './scripts/pdf.worker.js';
(window as any).pdfWorkerSrc = import('pdfjs-dist/webpack');

@Component({
  selector: 'zico-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:unload')
  private onUnload(): void {
    localForage.removeItem('utmData');
  }
  destroyed: Subject<boolean> = new Subject();
  subscription: Subscription = new Subscription();


  constructor(
    private _project: ProjectsService,
    private _gAnalytics: GAnalyticsService,
    private theme: ThemeService,
    private router: Router,
    private _mixpanelService: MixpanelService
  ) { }

  async ngOnInit(): Promise<void> {
    // Initialize Mixpanel
    this._mixpanelService.init();
    await this._mixpanelService.setUserProfile();

    // Initialize Google Analytics
    this._gAnalytics.initialiseGAConfig();

    this.router.events.pipe(
      takeUntil(this.destroyed),
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      // if (event.url.includes('utm_medium=Mailer') && !event.url.includes('documents')) {
      //   this._gAnalytics.sendGAEvent(GA_EVENTS.DOCUMENTS_ONBOARDING_LINK);
      // }
      this.trackPageViewsMixpanel(event.urlAfterRedirects);
      if (event.url.includes('dashboard')) {
        this.theme.loadStyle('responsive.css');
      }
    })

    this.subscribeRouterEvents();

  }


  subscribeRouterEvents() {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((e: any) => e instanceof RoutesRecognized),
          pairwise()
        )
        .subscribe((e: any) => {
          const previousUrl = e[0].urlAfterRedirects;
          localStorage.setItem('previousUrl', previousUrl);
        })
    );
  }

  async trackPageViewsMixpanel(currentUrl: string): Promise<void> {
    const pageViewPayload = await this._mixpanelService.getPageViewPayload(currentUrl);
    if (pageViewPayload && pageViewPayload.zUserId) { // Case where a user land on CPO for first time or logged out
      this._mixpanelService.track('Page View', pageViewPayload);
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.subscription.unsubscribe();
  }
}
