import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from 'src/app/@shared/models/loader';


@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  enabled = true;
  count = 0;
  initialLoadCompleted = new Subject();
  private loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  constructor() {
  }
  show() {
    if (this.enabled) {
      this.count++;
      this.loaderSubject.next(<LoaderState>{ show: true });
    }
  }
  hide() {
    if (this.enabled) {
      this.count--;
    }
    if (this.count <= 0) {
      this.loaderSubject.next(<LoaderState>{ show: false });
    }
  }
}
