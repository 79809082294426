/**
 * Check whether user is logged in using a mobile device
 * NOTE: Accuracy depends on user agent provided by browser,
 * Second part is for samsung galaxy devices.
 * @return Boolean True/ False
 */
export const isMobile = () => {
  return (
    /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent)
  );
};
