import { Injectable } from '@angular/core';
import { DATA_ATTRIBUTES_TRACKED } from '../constants/data-attributes';

@Injectable({
  providedIn: 'root'
})
export class DomService {

  constructor() { }




  getTrackedDataAttributesWithValues(element: HTMLElement) {
    const attributesTracked = Object.keys(DATA_ATTRIBUTES_TRACKED);
    let attributes = {};
    for (const attribute of attributesTracked) {
      let attributeValue = element.getAttribute(`data-${DATA_ATTRIBUTES_TRACKED[attribute]}`);
      if(attributeValue) attributes[attribute] = attributeValue;
    }
    return attributes;
  }
}
